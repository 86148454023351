import request from '@/utils/request'

/**
 * 获取云研数据
 */
export const getYunYanJobList = data => {
  return request({
    url: '/yunyan',
    params: data
  })
}

/**
 * 获取国聘数据
 */
export const getGuoPinJobList = data => {
  return request({
    url: '/guopin',
    params: data
  })
}

/**
 * 获取应届生数据
 */
export const getYjsJobList = data => {
  return request({
    url: '/yjs',
    params: data
  })
}

/**
 * 获取智联数据
 */
export const getZhiLianJobList = data => {
  return request({
    url: '/zhilian',
    params: data
  })
}
