import i18n from '@/i18n'

const t = i18n.global.t

export default () => [
  {
    label: t('msg.job.company'),
    prop: 'company'
  },
  {
    label: t('msg.job.job'),
    prop: 'job'
  },
  {
    label: t('msg.job.address'),
    prop: 'address'
  },
  {
    label: t('msg.job.salary'),
    prop: 'salary'
  },
  {
    label: t('msg.job.type'),
    prop: 'type'
  },
  {
    label: t('msg.job.time'),
    prop: 'time'
  },
  {
    label: t('msg.job.url'),
    prop: 'url'
  }
]
