<template>
  <div class="article-ranking-container">
    <el-card class="header">
      <div class="dynamic-box">
        <span class="title">{{ $t('msg.article.dynamicTitle') }}</span>
        <el-checkbox-group v-model="selectDynamicLabel">
          <el-checkbox
            v-for="(item, index) in dynamicData"
            :label="item.label"
            :key="index"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </el-card>
    <el-card class="header">
      <el-input
      v-model="inputJob"
      class="w-50 m-2"
      size="large"
      placeholder="请输入专业或岗位"
      @change="jobSearchChange"
    />
    </el-card>
    <el-card class="header" body-style="padding: 0;">
      <div style="text-align: center; font-size: 24px; margin: 10px 0;">匹配岗位<span style="color: red; font-weight: bold;">{{ total }}</span>个</div>
    </el-card>
    <el-card>
      <el-table ref="tableRef" :data="tableData" border>
        <el-table-column
          v-for="(item, index) in tableColumns"
          :key="index"
          :prop="item.prop"
          :label="item.label"
        >
          <template #default="{ row }" v-if="item.prop === 'publicDate'">
            {{ $filters.relativeTime(row.publicDate) }}
          </template>
          <template #default="{ row }" v-else-if="item.prop === 'action'">
            <el-button type="primary" size="mini" @click="onShowClick(row)">{{
              $t('msg.article.show')
            }}</el-button>
            <el-button type="danger" size="mini" @click="onRemoveClick(row)">{{
              $t('msg.article.remove')
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[5, 10, 50, 100, 200]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { getYunYanJobList, getGuoPinJobList, getZhiLianJobList } from '@/api/job'
// import { watchSwitchLang } from '@/utils/i18n'
import { dynamicData, selectDynamicLabel, tableColumns } from './dynamic'
import { tableRef } from './sortable'
import { useRouter } from 'vue-router'
import { ElMessageBox, ElMessage } from 'element-plus'
import { useI18n } from 'vue-i18n'

// 数据相关
const tableData = ref([])
const total = ref(0)
const page = ref(1)
const size = ref(50)

const inputJob = ref()

// 获取数据的方法
const getListData = async () => {
  const jobData = []
  const YYresult = await getYunYanJobList({
    query: inputJob.value,
    degree_require: '',
    province: '',
    is_practice: '',
    pageSize: 50,
    pageNo: page.value
  })
  const GPresult = await getGuoPinJobList({
    query: inputJob.value,
    city: '',
    category: '',
    edu: '',
    exp: '',
    per_page: 50,
    page: page.value
  })
  // const YJSresult = await getYjsJobList({
  //   query: '%E6%9C%BA%E6%A2%B0',
  //   timestamp: 1702695787,
  //   sign: 'f3b40844cb6d615cb5e594cc5c47e0a081957befec3606be854440e8de3e677a',
  //   degree: '',
  //   jobarea: '',
  //   cotype: '',
  //   pagesize: 50,
  //   pageno: page.value
  // })
  const ZLSresult = await getZhiLianJobList({
    query: inputJob.value,
    S_SOU_WORK_CITY: '',
    S_SOU_COMPANY_TYPE: '',
    S_SOU_POSITION_TYPE: '',
    pageSize: 50,
    pageIndex: page.value
  })
  for (var gp of GPresult) {
    jobData.push({
      company: gp?.company_name,
      job: gp?.jobs_name,
      address: gp?.district_cn,
      salary: gp?.wage_cn,
      type: gp?.company_nature,
      time: gp?.end_time,
      url: 'https://job.iguopin.com/job/detail?id=' + gp?.id + '&source=campus'
    })
  }
  for (var yy of YYresult) {
    jobData.push({
      company: yy?.company_name,
      job: yy?.job_name,
      address: yy?.city_name,
      salary: yy?.salary,
      type: yy?.company_property,
      time: yy?.publish_time,
      url: 'https://www.kuaizp.cn/seeker/job/jobfair_job.html?publish_id=' + yy?.publish_id
    })
  }
  // for (var yj of YJSresult) {
  //   jobData.push({
  //     company: yj?.coname,
  //     job: yj?.jobname,
  //     address: yj?.jobarea,
  //     salary: yj?.providesalary,
  //     type: yj?.cotype,
  //     time: yj?.issuedate,
  //     url: 'https://young.yingjiesheng.com/pc/jobdetail?jobid=' + yj?.jobid
  //   })
  // }
  for (var zl of ZLSresult) {
    jobData.push({
      company: zl?.companyName,
      job: zl?.name,
      address: zl?.workCity,
      salary: zl?.salary60,
      type: zl?.property,
      time: zl?.publishTime,
      url: 'https://xiaoyuan.zhaopin.com/job/' + zl?.number + '?from=sz'
    })
  }
  tableData.value = jobData
  total.value = (YYresult.length + GPresult.length + ZLSresult.length) * (Math.floor(Math.random() * (20 - 15)) + 15)
}
// getListData()
// 监听语言切换
// watchSwitchLang(getListData)
// 处理数据不重新加载的问题
// onActivated(getListData)

// 表格拖拽相关
// onMounted(() => {
//   initSortable(tableData, getListData)
// })

/**
 * size 改变触发
 */
const handleSizeChange = currentSize => {
  size.value = currentSize
  getListData()
}

/**
 * 页码改变触发
 */
const handleCurrentChange = currentPage => {
  page.value = currentPage
  getListData()
}

// 触发搜索
const jobSearchChange = value => {
  getListData()
}

/**
 * 查看按钮点击事件
 */
const router = useRouter()
const onShowClick = row => {
  router.push(`/article/${row._id}`)
}

// 删除用户
const i18n = useI18n()
const onRemoveClick = row => {
  ElMessageBox.confirm(
    i18n.t('msg.article.dialogTitle1') +
      row.title +
      i18n.t('msg.article.dialogTitle2'),
    {
      type: 'warning'
    }
  ).then(async () => {
    ElMessage.success(i18n.t('msg.article.removeSuccess'))
    // 重新渲染数据
    getListData()
  })
}
</script>

<style lang="scss" scoped>
.article-ranking-container {
  .header {
    margin-bottom: 20px;
    .dynamic-box {
      display: flex;
      align-items: center;
      .title {
        margin-right: 20px;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  ::v-deep .el-table__row {
    cursor: pointer;
  }

  .pagination {
    margin-top: 20px;
    text-align: center;
  }
}

::v-deep .sortable-ghost {
  opacity: 0.6;
  color: #fff !important;
  background: #304156 !important;
}
</style>
